export const BASE_URL = "https://inobackend-production.up.railway.app/api/v1";

export const Store_base_url =
  "https://inobackend-production.up.railway.app/api/v1/store";
export const USER_API = {
  Register_User: `${BASE_URL}/user/register`,
  Verify_OTP: `${BASE_URL}/user/verifyOTP`,
  Register_Profile: `${BASE_URL}/user/updateProfile`,
  Create_Post: `${BASE_URL}/user/createPost`,
  Post_Project: `${BASE_URL}/user/createProject`,
  Create_Product: `${BASE_URL}/user/createProduct`,
  Create_Service: `${BASE_URL}/user/createService`,
  Create_Job: `${BASE_URL}/user/createJob`,
  All_Product_list: `${BASE_URL}/user/allProductList`,
  Get_ALL_Products: `${BASE_URL}/user/getAllProductList`,
  Register_Store: `${BASE_URL}/user/registerStore`,
  Create_store: `${BASE_URL}/user/createJob`,
  Findproduct_SellerProductList: `${BASE_URL}/user/getSellerProductList`,
  Addproduct_buyerProductlist: `${BASE_URL}/user/getBuyerProductList`,
  Explore_service_SellerserviceList: `${BASE_URL}/user/getSellerServiceList`,
  List_service_buyerServiceList: `${BASE_URL}/user/getBuyerServiceList`,
  Findjob_seller_joblist:`${BASE_URL}/user/getSellerJobList`,
  PostJob_buyer_joblist:`${BASE_URL}/user/getBuyerJobList`,
  fetch_all_post_list : `${BASE_URL}/user/getAllPostList`,

  // bussiness card 
   create_Bussiness_card :`${BASE_URL}/user/createUserBusinessCard`,
   edit_Bussiness_Card : `${BASE_URL}/user/editBusinessCard `,
   delete_bussiness_Card : `${BASE_URL}/user/deleteBusinessCard`,
// referal
get_Referal_code :`${BASE_URL}/user/profile`,
add_Referal_code : `${BASE_URL}/user/addReferral`,
get_User_profile : `${BASE_URL}/user/profile`,

};

export const Store_Urls = {
  Register_Store: `${Store_base_url}/user/registerStore`,
  delete_store :  `${Store_base_url}/user/deleteUserStore`,
  update_user_store : `${Store_base_url}/user/updateUserStore`
};

export const Reseller_Urls = {
  Become_Reseller: `${BASE_URL}/user/sendOtpToMail`,
  Verify_reseller_OTP : `${BASE_URL}/user/verifyMailOtp`,
  BecomeReseller :`${BASE_URL}/user/becomeReseller`
};

export const Gov_Urls = {
  gov_project_List: `${BASE_URL}/user/getAllGovtProjectList`,
  Other_Projects : `${BASE_URL}/user/getAllProjectList`
};

export const wallet_url = {
  wallet_info: `${BASE_URL}/wallet/getWallet`,
  depoist_funs: `${BASE_URL}/wallet/depositFunds`,
  withdraw_funds :`${BASE_URL}/wallet/withdrawFunds`
  
};
